import { AppEnvironment } from './app-environment-interface';

export const environment: AppEnvironment = {
  production: true,
  showDevBanner: false,
  serviceHost: 'https://1call.cloud',
  socketHost: 'https://1call.cloud',
  sentry: {
    dsn: 'https://2aff92e559b14479f7004fb81423f7ff@o4508399619211264.ingest.us.sentry.io/4508400001744896',
    environment: 'prod',
    replayIntegrationOptions: { maskAllText: false },
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    tracePropagationTargets: ['https://1call.cloud/api', 'wss://1call.cloud/socket.io'],
    tracesSampleRate: 1,
  },
};
